import React, { useState } from 'react';

const TextAreaInput = ({ value, onChange }) => {
  return (
    <textarea
      className='form-control'
      name='medical_condition'
      value={value}
      onChange={onChange}
      required
    />
  );
};

const MedicalConditionInput = ({ label, onChange }) => {
  const [hasMedicalCondition, setHasMedicalCondition] = useState('');
  const [medicalConditionDetails, setMedicalConditionDetails] = useState('');

  return (
    <div className='mb-3'>
      <label>{label}</label>
      <div>
        <input
          type='radio'
          name='hasMedicalCondition'
          value='yes'
          checked={hasMedicalCondition === 'yes'}
          onChange={() => {
            setHasMedicalCondition('yes');
          }}
          id='hasMedicalConditionYes'
          required
        />
        <label className='ml-2 mr-4' htmlFor='hasMedicalConditionYes'>
          Yes
        </label>

        <input
          type='radio'
          name='hasMedicalCondition'
          value='no'
          checked={hasMedicalCondition === 'no'}
          onChange={() => {
            setHasMedicalCondition('no');
            setMedicalConditionDetails('');
          }}
          required
          id='hasMedicalConditionNo'
        />
        <label className='ml-2' htmlFor='hasMedicalConditionNo'>
          No
        </label>
      </div>

      {hasMedicalCondition === 'yes' && (
        <div className='mt-2'>
          <label>Please describe your medical condition:</label>
          <textarea
            className='form-control'
            name='medical_condition'
            // value={value}
            onChange={onChange}
            required
          />
          <div className='my-2'>
            <small className=''>
              <small>
                I understand that disclosing this information will help my child
                to be served better. I however release SKY from any
                responsibility of complications that may occur beyond the
                provision of first response services and agree that they can
                call me for further directions if need be.
              </small>
            </small>
          </div>
        </div>
      )}
    </div>
  );
};

export { MedicalConditionInput, TextAreaInput };
