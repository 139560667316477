import React from 'react';

// Define the locations array with sorted areas
const locations = [
  {
    city: 'Nairobi',
    areas: [
      'Rubia/Otiende',
      'Waithaka/Riruta',
      'South C',
      'South B',
      'Ngumo',
      'Jamhuri/Woodley',
      'Buruburu/Donholm/Umoja',
      'Imaara/Embakasi',
      'Thika Rd',
      'Kilimani/Lavington/Kileleshwa',
      'Ngara/Pumwani',
      'Westlands/Parklands',
      'Runda/Loresho/Ridgeways',
      'Karen/Langata',
      'Njiru/Ruai',
    ].sort(),
  },
  {
    city: 'Kisumu',
    areas: [
      'Manyatta',
      'Kondole',
      'Kisian',
      'Milimani',
      'Nyamasaria',
      'Kibuye',
      'Nyalenda',
      'Riat',
      'Kiboswa',
      'Rabuor',
    ].sort(),
  },
  {
    city: 'Mombasa',
    areas: [],
  },
  {
    city: 'Nakuru',
    areas: [],
  },
  {
    city: 'Eldoret',
    areas: [],
  },
];

function SKYFestLocations({ value, onChange, required = false }) {
  return (
    <div className='mb-3'>
      <label>Location</label>
      <select
        className='form-control'
        name='location'
        value={value || ''}
        onChange={onChange}
        required={required}>
        <option value='' hidden>
          Select a location
        </option>

        {/* Dynamically render cities and areas */}
        {locations.map((location) => (
          <React.Fragment key={location.city}>
            {location.areas.length > 0 ? (
              <optgroup label={location.city}>
                {/* Sorted areas */}
                {location.areas.map((area) => (
                  <option key={area} value={area}>
                    {area}
                  </option>
                ))}
                {/* Other option for this city */}
                <option value={location.city}>Other ({location.city})</option>
              </optgroup>
            ) : (
              <option value={location.city}>{location.city}</option>
            )}
          </React.Fragment>
        ))}

        {/* Global Other option */}
        <option value='Other'>Other</option>
      </select>
    </div>
  );
}

export default SKYFestLocations;
