import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register FilePond plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const SERVER = global.server;

/**
 * @param {function} onChange - Callback invoked when a file is uploaded or removed. onChange(fileId or null)
 * @param {boolean} required  - Whether the uploader is required for form validation
 */
const DocumentUploader = ({ onChange = () => {}, required = false }) => {
  const [files, setFiles] = useState([]);

  // Called when FilePond’s file list changes (upload success or removal)
  const handleUpdateFiles = (fileItems) => {
    setFiles(fileItems);

    if (fileItems.length === 0) {
      // No file => reset parent state
      // onChange(null);
      return;
    }

    // If the file has just been uploaded, it has a serverId or similar
    const [uploadedFile] = fileItems;
    const fileRef = uploadedFile.serverId || uploadedFile.filename;
    // onChange(fileRef);
  };

  return (
    <div className='document-uploader'>
      <FilePond
        files={files}
        onupdatefiles={handleUpdateFiles}
        allowMultiple={false}
        maxFiles={1}
        acceptedFileTypes={['image/*']}
        name='uri' // Field name in FormData
        required={required}
        // Provide only the 'process' function; no 'load' or 'revert' since we don't do server previews or reverts
        server={{
          process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) => {
            // Create form data
            const formData = new FormData();
            formData.append('uri', file, file.name);

            // Create the request
            const request = new XMLHttpRequest();
            request.open('POST', `${SERVER}/document-upload`);

            // Optionally add authorization header
            const token = localStorage.getItem('feathers-jwt');
            if (token) {
              request.setRequestHeader('Authorization', `Bearer ${token}`);
            }

            // Track upload progress
            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            };

            request.onload = () => {
              if (request.status >= 200 && request.status < 300) {
                try {
                  const response = JSON.parse(request.responseText);
                  // Expecting { id: 'someFileId' }
                  if (response && response.id) {
                    onChange(response.id);
                    load(response.id); // This sets serverId in FilePond
                  } else {
                    console.error('Invalid response:', response);
                    error('Invalid response from server');
                  }
                } catch (parseErr) {
                  console.error('Error parsing response:', parseErr);
                  error('Error processing server response');
                }
              } else {
                try {
                  const errorResponse = JSON.parse(request.responseText);
                  console.error('Server error:', errorResponse);
                  error(errorResponse.message || 'Upload failed');
                } catch (parseErr) {
                  console.error('Error parsing error response:', parseErr);
                  error('Upload failed');
                }
              }
            };

            request.onerror = () => {
              console.error('Network error during upload');
              error('Network error');
            };

            // Send form data
            request.send(formData);

            return {
              // Allow FilePond to cancel the request
              abort: () => {
                request.abort();
                abort();
              },
            };
          },
        }}
        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span>'
      />

      {/** Optional: HTML5 constraint validation if required */}
      {required && files.length === 0 && (
        <input
          type='text'
          required
          value=''
          style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
          onChange={() => {}}
        />
      )}
    </div>
  );
};

export default DocumentUploader;
