import React, { useState, useEffect, useRef } from 'react';

function AgePicker({ onChange, onBlur, required = true, className = '' }) {
  // Local states
  const [YYYY, setYYYY] = useState('');
  const [MM, setMM] = useState('');
  const [DD, setDD] = useState('');

  // Track how many inputs are currently focused
  const [focusCount, setFocusCount] = useState(0);

  // A ref to hold the timeout ID for debouncing onChange
  const changeTimeoutRef = useRef(null);

  // This function is called anytime we want to recalc or
  // notify the parent of a partial date: YYYY-MM-DD
  const updateDate = (newYYYY, newMM, newDD) => {
    // Clear any existing debounced calls
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current);
    }

    // Start a new debounce
    changeTimeoutRef.current = setTimeout(() => {
      if (onChange) {
        onChange(`${newYYYY || ''}-${newMM || ''}-${newDD || ''}`);
      }
    }, 500);
  };

  // Called when an input gains focus
  const handleFocus = () => {
    setFocusCount((count) => count + 1);
  };

  // Called when an input loses focus
  const handleBlur = () => {
    setFocusCount((count) => count - 1);
  };

  // Whenever focusCount changes, if it hits 0,
  // we parse and validate the final date, then call onBlur if valid
  useEffect(() => {
    if (focusCount === 0) {
      // We only validate & call `onBlur` if all fields are out of focus
      validateAndNotifyBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusCount]);

  // Validate date & call onBlur
  const validateAndNotifyBlur = () => {
    if (!onBlur) return;

    // If any of the fields are blank, do nothing special
    if (!YYYY || !MM || !DD) {
      return;
    }

    // Parse the date
    const dateString = `${YYYY}-${MM}-${DD}`;
    const timestamp = Date.parse(dateString);

    if (isNaN(timestamp)) {
      // Invalid date => clear day & year
      setYYYY('');
      setDD('');
    } else {
      onBlur(dateString);
    }
  };

  // Handle changes for each field
  const handleYearChange = (e) => {
    const value = e.target.value;
    setYYYY(value);
    updateDate(value, MM, DD);
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setMM(value);
    updateDate(YYYY, value, DD);
  };

  const handleDayChange = (e) => {
    const value = e.target.value;
    setDD(value);
    updateDate(YYYY, MM, value);
  };

  return (
    <div className={`age-picker ${className}`}>
      {/* Month Select */}
      <div>
        <select
          className='form-control'
          required={required}
          value={MM}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleMonthChange}>
          <option value='' hidden>
            Month
          </option>
          <option value='1'>January</option>
          <option value='2'>February</option>
          <option value='3'>March</option>
          <option value='4'>April</option>
          <option value='5'>May</option>
          <option value='6'>June</option>
          <option value='7'>July</option>
          <option value='8'>August</option>
          <option value='9'>September</option>
          <option value='10'>October</option>
          <option value='11'>November</option>
          <option value='12'>December</option>
        </select>
      </div>

      {/* Day Input */}
      <div className='mx-2'>
        <input
          type='number'
          className='form-control day-input'
          required={required}
          value={DD}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleDayChange}
          placeholder='Day'
        />
      </div>

      {/* Year Input */}
      <div>
        <input
          type='number'
          className='form-control year-input'
          required={required}
          value={YYYY}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleYearChange}
          placeholder='Year'
        />
      </div>
    </div>
  );
}

export default AgePicker;
